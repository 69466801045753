@font-face {
    font-family: Rubik;
    src: url("../../../assets/fonts/Rubik-Regular.ttf");
}

.user {
    z-index: 100;
    position: relative;
    top: 26px;
}

.user-profile {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 25px;
    top: 85px;

}
.user-profile .default-profile-container{    
    padding: 5px;
    background: #c7c3f563;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.user-profile .default-profile{
    font-size: 48px;
}

.user-level {
    position: absolute;
    width: 126px;
    height: 40px;
    left: 110px;
    top: 92px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: #5C55BF;
    overflow: hidden;
}

.items {
    height: 53px;
    left: 0px;
    top: 0px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #5C55BF;
    padding: 20px;
    font-weight: bold;
    position: relative;
}

.items>a {
    color: #BEC3CE;
}

.items>a>img {
    position: relative;
    left: -7.75%;
    right: 19.25%;
    top: -10.62%;
    bottom: -6%;
    height: 25.25px;
    width: 25.375px;
    /* left: 2.8125px; */
    /* top: 3.75px; */
    border-radius: 0px;
}

.dashboard-nav {
    position: absolute;
    width: 250px;
    height: auto;
    left: 37px;
    top: 227px;
    background: #FAFAFA;
    box-shadow: 0px 4px 46px rgb(0 0 0 / 25%);
    border-radius: 10px;

}

/* .compliance-logo {
    position: absolute;
    width: 100px;
    height: 42px;
    left: 26px;
    top: 14px;
}

.science-logo {
    position: absolute;
    width: 100px;
    height: 42px;
    left: 172px;
    top: 14px;
} */

/* .life-sciencelogo {
    position: absolute;
    width: 100px;
    height: 42px;
    left: 311px;
    top: 14px;

} */

.contact-card {
    position: absolute;
    width: 263px;
    height: 246px;
    left: 34px;
    top: 569px;
    background: #FAFAFA;
    box-shadow: 0px 4px 46px rgb(0 0 0 / 25%);
    border-radius: 10px;
}

.contact-title {
    width: 111px;
    height: -92px;
    /* left: 82px; */
    /* top: 838px; */
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #5C55BF;
}
.contact-desc{
    width: 142px;
    height: 68px;
    /* left: 82px; */
    /* top: 926px; */
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #BEC3CE;
    padding-top: 10px;
}
.contact-today{
    width: 173px;
    position: absolute;
    height: 21px;
    /* left: 82px; */
    top: 153px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 58px;
    color: #5C55BF;
}
.man{
    position: absolute;
    left: 38%;
    right: 0%;
    top: 5%;
    bottom: 0%;
    width: 193px;
    height: 193px;
}
.msg{
    position: absolute;
    left: 65.01%;
    right: 74.13%;
    top: -34.79%;
    bottom: 41.85%;
    width: 150px;
    height: 150px;
}
.corner {
    position: absolute;
    width: 465px;
    height: 321px;
    left: -3px;
    /* top: 0px; */
    z-index: -1;
  
  }

  
  .items > a:hover, a:active {
      color:#5C55BF !important;
  }
  path:hover , :active{
    fill: transparent !important;

  }
  .user-menu-popup .MuiPopover-paper{
    top: 131px !important;
    left: 266px !important
  }
  .user-menu-popup .MuiPopover-paper ul{
    padding-left: 0px; 
    margin: 0px !important;
  }
  .user-menu-popup .MuiPopover-paper .link-collapse{
    padding: 0px;
  }
  .user-menu-popup .MuiPopover-paper ul li{
    padding: 0px 10px 10px 10px;
    font-size: 18px;
  }

  .logos{
    position: absolute;
    display: flex;
    margin-left: 25px;
  }
  .logos img{
    width: 145px;
    margin: 20px 40px 0px 0px;
  }
  