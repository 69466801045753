.error-container{
    width: 100%;
    height: 100vh;
    background-color: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message{
    color : #9772FB;
    font-weight: bold;
}