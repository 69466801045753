.react-calendar__viewContainer {
    line-height: 23px !important;
    font-size: 16px !important;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 400 !important;
    top: 32px !important;
    position: relative !important;
    text-align: center !important;

}

.category__title {
    text-transform: capitalize;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 16px;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;

}


.react-calendar__navigation__label {
    position: absolute;
    top: 2px !important;
    left: auto;
    right: 15px !important;
    background: transparent;
    border: none;

}

.react-calendar__tile--now {
    font-weight: bold;
    color: #323232;
    
    background-color: #6f48eb33;
    border-radius: 50%;
    border-color: #323232;
    border-width: 1px;
}
.react-calendar__month-view__weekdays__weekday{
}

.react-calendar__tile--active > abbr{
    background-color: #ffffff;
    border-radius: 50%;
    width:20%  !important;
    font-weight: bold;
    color: #5C55BF;
}

.calender {
    margin-top: 10px
}

/* Calender Section */
.react-calendar {

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #2D3142;
    position: relative;
    top: -34px;
    width: 100%;
    overflow: hidden;
    height: 350px;

}

.react-calendar__tile {

    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    border: none;
    padding: 16px
}

.category__box .react-calendar__month-view {
    min-height: 330px;
}

.react-calendar__navigation {
    position: relative;
}