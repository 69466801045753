.software__inventory {
  margin-left: 100px;
  padding: 32px;
}

.MuiToolbar-regular {
  min-height: 0 !important;
}

@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 0 !important;
  }
}

@media (min-width: 0px) and (orientation: landscape) {
  .MuiToolbar-regular {
    min-height: 0 !important;
  }
}
