body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  width: 100% !important;
  height: 100vh !important;
  transform-origin: top left;
  overflow-x:hidden;
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
.pencil {
  width: 10px;
  height: 10px;
  background: dimgray;
  position: relative;
  transform: rotate(30deg);
  margin: -20px 50px;
  cursor: pointer;
}
.pencil::before {
  content: "" ;
  position: absolute ;
  width: 10px ;
  height: 10px ;
  background: dimgray ;
  top: -13px ;
}
.pencil::after {
  content: "" ;
  position: absolute ;
  width: 8px ;
  height: 8px ;
  background: dimgray ;
  left: 1px ;
  bottom: -4px ;
  transform: rotate(45deg);
}
#profile-photo-input{
  display: none;
}

.MuiTableSortLabel-icon{
  color:white !important;
  opacity:1 !important
}
.user-dropdown{
  position: absolute;
  width: 19px;
  height: 40px;
  left: 263px;
  top: 93px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
}
.MuiTable-root > th {
padding-left: 10px;
}