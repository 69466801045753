.App {
  background: #fafafa;
}
body{
  cursor: auto!important;
}
.alert,
.brand,
.btn-simple,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.navbar,
.td-name,
a,
body,
button.close,
h1,
h2,
h3,
h4,
h5,
h6,
p,
td {
  font-family: 'Rubik';
  font-weight: normal;
}

.main-header {
  z-index: 999 !important;
}

.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a {
  background-color: #5c55bf !important;
}
.hiddenHover, .hiddenHoverIn{
  display: none;
  position: absolute;
  background-color: black;
  color: #fff;
  top: 55px;
  left: -20px;
  padding: 5px 10px;
  border-radius: 10px;
  z-index: 99;
}
#apexchartsbasicxbar path:active{
  fill:#5C55BF!important;
}
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.hiddenHoverIn{
  left: -50px;
}
button:hover>.hiddenHover, button:hover>.hiddenHoverIn{
  display: block;
}
.content-wrapper {
  width: 100%;
  height: -webkit-fill-available;
  position: absolute;
}
.image-upload>input {
  display: none;
}
.image-upload>label>img{
  width: 35px;
  cursor: pointer;
}
.content-wrapper .row {
  height: 100%;
}

.banner-img {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.auth-content {
  width: 80%;
  margin: auto;
}

.auth-wrapper .auth-side-img {
  padding-right: 400px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;
}

.aut-bg-img .auth-content {
  padding: 70px 40px;
}

.bg-white {
  background-color: white !important;
}

.auth-wrapper .auth-content {
  position: relative;
  padding: 15px;
  z-index: 5;
}

.auth-wrapper .auth-side-form {
  position: relative;
  min-height: 100%;
  background: #fff;
  display: inline-flex;
  align-items: center;
  left: auto;
  float: right;
}

.auth-wrapper .auth-content:not(.container) {
  width: 500px;
}

.logo {
  font-family: 'Open Sans', sans-serif;
}

.login-logo {
  width: 25%;
}

.aut-bg-img .auth-content {
  padding: 70px 40px;
}

.auth-wrapper .auth-side-form > * {
  position: relative;
  z-index: 5;
}

.main-banner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  opacity: 0.8;
  bottom: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 0px;

  background: url("./component/assets/office-support-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.form-container {
  display: flex;  
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 100%;
}

.chart-350 {
  height: 350px !important;
}

.dashboard-count p {
  color: #000000d9;
}

/* ******* Root Css ************ */
.status {
  padding: 8px 24px;
  width: fit-content;
  border-radius: 40px;
  font-size: 13px;
}

.status-active {
  background: #e6e6f2;
  color: #4a4aff;
  margin: auto;
}

.status-suspended {
  background: #e6e6f2;
  margin: auto;
  color: rgb(241 88 96);
}
.status-warning {
  background: #e6e6f2;
  margin: auto;
  color: rgb(230, 164, 42);
}
.form-control {
  padding-left: 5px!important;
}
.changepassModal .form-control{
  border: 1px solid!important;
}
.status-success {
  background: #e6e6f2;
  color: rgb(36, 145, 22);
  margin: auto;
}

.status-pending {
  background: #ede7b3;
  color: rgb(110, 104, 147);
  margin: auto;
}
td> .d-flex{
  justify-content: center;
}
.status:before {
  content: '\A';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-active:before {
  background-color: #4a4aff;
}

.status-pending:before {
  background-color: rgb(110, 104, 147);
}

.status-suspended:before {
  background-color: rgb(241 88 96);
}
.status-warning:before {
  background-color: rgb(230, 164, 42);
}

.status-success:before {
  background-color: rgb(36, 145, 22);
}

form label {
  font-weight: 700;
}

/* ******* Custom Css ********** */
.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.MuiToolbar-root .MuiInput-root {
  background: #f4f2ff;
  padding: 5px;
  border-radius: 6px;
  padding-left: 15px;
}

.MuiToolbar-root .MuiInput-root input {
  width: 300px;
}

.MuiInput-underline:before {
  content: none !important;
}

.MuiTableCell-root {
  padding: 0 !important;
  padding: 10px 0 !important;
}

.MuiTableCell-root a {
  text-decoration: none;
  color: inherit;
}

.MuiTableCell-head {
  background-color: #f4f2ff !important;
  font-weight: bold;
}

.MuiTableCell-head div {
  font-weight: bold;
}

.MuiTableCell-footer {
  border-bottom: 0 !important;
  float: none !important;
}

.MuiTable-root table th {
  background: #f4f2ff !important;
}

.MuiTable-root td:first-child,
.MuiTable-root th:first-child {
  /* text-align: center; */
  padding-left: 30px !important;
}

.MuiTable-root td,
.MuiTable-root th {
  /* text-align: center; */
  padding-left: 15px !important;
  padding-right: 15px!important;
}

.MuiTable-root td {
  text-transform: capitalize;
  padding: 10px 0 !important;
}

.MuiTable-root td i {
  border-radius: 0 !important;
  padding: 0 !important;
  width: 25px !important;
  height: 25px !important;
  font-size: 11px !important;
  border-radius: 3px !important;
}

.MuiTableBody-root .table-icon {
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  color: white;
  cursor: pointer;
}

.MuiTableBody-root .table-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Component-paginationRoot-1 {
  width: 10%;
}

.users th:last-child,
.users th:nth-last-child(2) {
  text-align: center;
}

.faq-container h1 {
  color: #858585;
  font-size: 24px;
}

.faq-container p {
  color: #858585;
  font-size: 18px;
}

.faq-cards {
  height: 200px;
  color: #858585;
  box-shadow: none;
  border-radius: 10px !important;
}

.faq-cards i {
  font-size: 20px;
}

.faq-cards h5 {
  font-size: 18px;
  color: #858585;
  margin-bottom: 5px;
}

.ant-collapse {
  border: none;
}

.primary__save__button {
  background: #5c55bf;
  color: #ffffff;
}

.primary__cancel__button {
  border: 1px solid #5c55bf;
  background: transparent;
}

.adminmenu-popup{
  top: 0px !important;
  left: 65px !important
}
.adminmenu-popup ul{
  padding-left: 0px; 
  margin: 0px !important;
}
.adminmenu-popup .link-collapse{
  padding: 0px;
}
.adminmenu-popup ul li{
  padding: 0px 10px 10px 10px;
  font-size: 18px;
}
.fs-18{
  font-size: 18px;
}
.MIcons{
  fill: #808080 !important;
  font-size: 24px !important;
  margin-right: 10px;
}
