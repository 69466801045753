.side__bar .default-profile-container{    
    padding: 5px;
    background: #c7c3f563;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.side__bar .default-profile{
    font-size: 28px;
}
/* header */
.header__section {
    padding: 32px 32px 0px 100px;
    display: flex;
    justify-content: end;
}
.header__section img{
    width: 150px;
}
/* header */
.corner {
    position: absolute;
    width: 465px;
    height: 321px;
    left: -3px;
    /* top: 0px; */
    z-index: -1;
  
  }